.maindiv {
  padding: 0 1.5rem;
  position: relative;
  overflow: hidden;
  margin: 0 !important ;
  /* margin-top: 10%; */
}
.subParentDiv {
  margin-top: 10%;
  border: white 2px dotted;
  /* position: relative; */
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  margin-bottom: 5rem;
  height: 100%;
}
.parent {
  margin: 2rem;
  justify-content: center;
  align-items: center;
}
.buttonContainer {
  width: 25%;
  height: 2.75rem;
  text-align: center;
  align-items: center;
  padding: 0.625rem 0.8125rem 0.625rem 0.75rem;
  border-radius: 0.25rem;

  margin-bottom: 0.5rem;
  margin-right: 0;
}
.buttonas-vodafonerContainer {
  width: 80%;
  height: 2.75rem;
  text-align: center;
  align-items: center;
  padding: 0.625rem 0.8125rem 0.625rem 0.75rem;
  border-radius: 0.25rem;

  margin-top: 0.5rem;
}
.button-login {
  width: 60%;
  height: 2.75rem;
  text-align: center;
  align-items: center;
  padding: 0.625rem 0.8125rem 0.625rem 0.75rem;
  border-radius: 0.25rem;

  margin-top: 0.5rem;
  margin-right: 2.95%;
  margin-left: 2.95%;
}
.btnText {
  width: 18.3125rem;
  height: 1.5rem;

  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;

  line-height: 1.5;

  text-align: "center";
}
.button-login:hover {
  color: black;
}
.logoContainer {
  height: 6.5rem;
  width: 10rem !important;
}
.mentor-card-screen-background {
  object-fit: cover;
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  color: white;
  display: flex;
  justify-content: center;
}
.signin-container-dev {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  background: linear-gradient(180deg, #f03e40 0%, #ab3e40 100%);
}
.vodafone-image {
  object-fit: cover;
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("../../assets/vod-back.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
