.rejected-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(240, 13, 13);
  color: white;
}
.approved-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: darkseagreen;
  color: black;
}
.phone-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(75, 9, 9);
  color: white;
}
.inprogress-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(255, 187, 0);
  color: black;
}
.assigned-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(255, 102, 0);
  color: white;
}
.filter-text {
  border: 0.1rem solid rgb(216, 213, 213);
  border-radius: 0.4rem;
}

.invoice.checked {
  background-color: #ccc;
  color: #222;
}
.admin-order-action {
  cursor: pointer;
}
.admin-modal-container {
  position: relative;
  overflow-y: auto;
  top: 25%;
  left: 35%;
  padding: 3rem;
  background-color: white;
  height: fit-content;
  width: fit-content;
  border: 0.1rem solid rgb(180, 10, 10);
  border-radius: 10px;
}
.close-admin-modal-icon {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  cursor: pointer;
}

.close-admin-modal-icon:hover {
  transform: scale(1.1);
}
.rejection-input {
  border-radius: 0.5rem;
}

.searchRectangle {
  height: 11rem;
  border-radius: 5px 10px 10px 5px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px -43px #848484;
  padding: 20px;
  border-top: solid;
  border-color: #e60000;
  margin-top: 2rem;
}
.Counters {
  height: 11rem;
  padding-left: 30px;
}
.backgroundTwo {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;
  background-color: darkseagreen;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.backgroundOne {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;
  background-color: rgb(60, 177, 60);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.statusone {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(60, 177, 60);
  color: black;
}
.locked-camera-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(26, 8, 8);
  color: white;
}
.backgroundnine {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;
  background-color: rgb(26, 8, 8);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.backgroundThree {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;
  background-color: rgb(240, 13, 13);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.backgroundFour {
  background-color: rgb(75, 9, 9);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.backgroundFive {
  background-color: rgb(255, 187, 0);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.backgroundSix {
  background-color: rgb(255, 102, 0);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.backgroundSeven {
  background-color: rgb(18, 102, 141);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.unrecognized-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(18, 102, 141);
  color: white;
}
.backgroundeight {
  background-color: rgb(124, 6, 15);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  align-items: center;
  border-radius: 4px;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02);
}
.locked-status {
  border: 0.1rem solid rgb(248, 250, 248);
  border-radius: 0.5rem;
  background-color: rgb(124, 6, 15);
  color: white;
}
.material-icons-requests {
  color: white;
  font-size: 50px;
}
.number {
  color: #ffffff;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-bottom: 0.5rem;
}

.canceled-requests {
  color: #ffffff;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.2px;
  margin: 0;
}

.tableclass {
  border-radius: 5px 10px 10px 5px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px -43px #848484;
  margin: 1.2rem;
  padding: 0.5rem 2rem;
  border-top: solid;
  border-color: #e60000;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e60000;
  color: #e60000;
  margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
}

.pagination li a {
  font-weight: bold;
}

.pagination li.active a {
  color: #fff;
  background: #e60000;
}

.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
